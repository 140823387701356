import React, { useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import { useHistory } from 'react-router-dom';

import { API_BASE_PATH } from '../../config/api';
import useStore from '../../stores';
import MarkdownEditor from '../../components/MarkdownEditor/MarkdownEditor';
import Table from '../../components/Table';

export default function KeyInsight () {
  const history = useHistory();
  const { id } = useParams();
  const setRoute = useStore(state => state.route.setRoute);
  const token = useStore(state => state.admin.token);
  const setAdmin = useStore(state => state.admin.setAdmin);
  const [fetching, setFetching] = useState(false);
  const [params, setParams] = useState({});
  const [updated, setUpdated] = useState(false);
  const [errors, setErrors] = useState([]);
  const getKeyInsight = async () => {
    setFetching(true);
    const res = await fetch(`${API_BASE_PATH}/ops/admins/keyInsights/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
      const { keyInsight, outcomesAndActions } = await res.json();
      setParams({ ...keyInsight, outcomesAndActions });
      setFetching(false);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
      setFetching(false);
    } else {
      const { error } = await res.json();
      setErrors(prevArray => [...prevArray, error]);
      setFetching(false);
    }
  };
  const updateKeyInsight = async () => {
    setErrors([]);
    setUpdated(false);

    const updateData = {
      name: params.name,
      about: params.about,
      published: params.published,
    };

    const res = await fetch(`${API_BASE_PATH}/ops/admins/keyInsights/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateData),
    });
    if (res.status === 200) {
      const { keyInsight } = await res.json();
      if (id === 'new') {
        history.push(`/keyInsights/${keyInsight.id}`);
      } else {
        getKeyInsight();
      }
      setUpdated(true);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
    } else {
      const { error } = await res.json();
      if (Array.isArray(error)) {
        setErrors(prevArray => [...prevArray, ...error]);
      } else {
        setErrors(prevArray => [...prevArray, error]);
      }
    }
  };
  useEffect(() => {
    setRoute('keyInsights');
    if (id !== 'new') {
      getKeyInsight();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const rangesTableColumns = [
    {
      Header: 'Range Lower',
      accessor: 'range_lower',
    },
    {
      Header: 'Range Upper',
      accessor: 'range_upper',
    },
    {
      Header: 'Evaluation',
      accessor: 'evaluation',
    },
    {
      Header: 'Evaluation Color',
      accessor: 'evaluation_color',
    },
    {
      Header: 'Evaluation Text',
      accessor: 'evaluation_text',
    },
  ];
  const outcomesTableColumns = [
    {
      Header: 'Action Id',
      accessor: 'ap_id',
    },
    {
      Header: 'Action title',
      accessor: 'ap_title',
    },
    {
      Header: 'Desired outcome text',
      accessor: 'desired_outcome_title',
    },
    {
      Header: 'Criteria level',
      accessor: 'criteria_level',
    },
  ];

  return (
    <div className='flex flex-col flex-auto bg-gray-50 items-stretch max-w-full justify-center'>
      <div className='relative flex-auto mx-auto my-0 py-0 px-4 lg:px-12 max-w-6xl w-full'>
        <div className='pt-4 lg:pt-6 px-0 relative w-full'>
          <div className='flex justify-between'>
            <div className='mt-0 flex-auto self-center'>
              <h1 className='font-serif text-xl lg:text-2xl break-word leading-8 m-0'>
                Key Insight {id}
              </h1>
            </div>
            <div className='flex flex-auto content-end items-start justify-end ml-6 whitespace-nowrap'>
              <div className='ml-2 lg:ml-4 mt-0 flex'>
                <button
                  onClick={() => updateKeyInsight()}
                  disabled={fetching}
                  className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-purple-500 enabled:hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75 disabled:opacity-25'
                >
                  Save
                </button>
              </div>
            </div>
          </div>

          <div className='mb-3'>
            {updated ? (
              <div className='bg-green-50 outline-none shadow-md rounded-md border border-solid border-green-500 mt-4'>
                <div className='flex flex-col p-6'>
                  <div className='font-medium'>
                    Key Insight {id} successfully updated
                  </div>
                </div>
              </div>
            ) : null}
            {errors && errors.length > 0 ? (
              <div className='bg-pink-50 outline-none shadow-md rounded-md border border-solid border-pink-500 mt-4'>
                <div className='flex flex-col p-3'>
                  <div className='font-semibold'>
                    There{' '}
                    {errors.length === 1
                      ? 'is 1 error'
                      : `are ${errors.length} errors`}{' '}
                    with this key insight:
                  </div>
                  <div className='ml-4 pl-4'>
                    <ul className='list-disc'>
                      {errors.map((error, index) => {
                        return (
                          <li key={index} className='mt-2'>
                            {error}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
            <form autoComplete='off' className='mb-10 lg:flex'>
              <div className='flex flex-col justify-center items-start lg:flex-1'>
                <div className='flex flex-col mt-6 lg:flex-1 w-full'>
                  <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300'>
                    <div className='p-8 rounded-md'>
                      <div className='pb-4'>
                        <h2 className='font-serif font-semibold m-0 text-lg'>
                          Key Insight information
                        </h2>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>Name</label>
                          <input
                            placeholder='Name'
                            value={params.name}
                            onChange={e =>
                              setParams(prevState => ({
                                ...prevState,
                                name: e.target.value,
                              }))
                            }
                            className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                          />
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>Age Low</label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.age_low}
                          </span>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>Age High</label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.age_high}
                          </span>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Sampling Type
                          </label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.sampling_type}
                          </span>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Category Key
                          </label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.category_key}
                          </span>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>Created at</label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.created_at}
                          </span>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>Updated at</label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.updated_at}
                          </span>
                        </div>
                      </div>

                      <div className='mt-4 flex-1 max-w-full'>
                        <div className='flex'>
                          <input
                            className='cursor-pointer w-4'
                            type='checkbox'
                            checked={params.published}
                            onChange={e =>
                              setParams(prevState => ({
                                ...prevState,
                                published: Number(e.target.checked),
                              }))
                            }
                          />
                          <label className='ml-2 pt-1 text-sm'>Published</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col mt-6 lg:flex-1 w-full'>
                  <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300'>
                    <div className='p-8 rounded-md' data-color-mode='light'>
                      <div className='pb-4'>
                        <div className='flex justify-between'>
                          <div className='mt-0 flex-auto self-center'>
                            <h2 className='font-serif font-semibold m-0 text-lg'>
                              About
                            </h2>
                          </div>
                        </div>
                      </div>
                      <MarkdownEditor
                        height={300}
                        value={params.about}
                        onChange={value => {
                          setParams(prevState => ({
                            ...prevState,
                            about: value,
                          }));
                        }}
                        highlightEnable={false}
                        autoSyncScroll={true}
                      />
                    </div>
                  </div>
                </div>

                <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 mt-4 w-full'>
                  <div className='p-8 rounded-md'>
                    <div className='pb-4'>
                      <h2 className='font-serif font-semibold m-0 text-lg'>
                        Associated Ranges
                      </h2>
                      {params?.gut_vaginal_health_ranges?.length > 0 ? (
                        <div className='flex-1 max-w-full'>
                          <div className='flex flex-col relative'>
                            <div className='w-full border-collapse'>
                              <Table
                                type={'gut_vaginal_health_ranges'}
                                columns={rangesTableColumns}
                                staticData={params.gut_vaginal_health_ranges}
                                disabled={filter => true}
                              ></Table>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 mt-4 w-full'>
                  <div className='p-8 rounded-md'>
                    <div className='pb-4'>
                      <h2 className='font-serif font-semibold m-0 text-lg'>
                        Desired outcomes and actions
                      </h2>
                      {params?.outcomesAndActions?.length > 0 ? (
                        <div className='flex-1 max-w-full'>
                          <div className='flex flex-col relative'>
                            <div className='w-full border-collapse'>
                              <Table
                                type={'outcomesAndActions'}
                                columns={outcomesTableColumns}
                                staticData={params.outcomesAndActions}
                                disabled={filter => true}
                              ></Table>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className='pt-2 text-sm'>
                          No outcomes related to this insight
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
