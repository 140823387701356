import React, { useState, useEffect } from 'react';
import { NavLink, Link, useParams } from 'react-router-dom';

import { API_BASE_PATH } from '../../config/api';
import { API_PAGE_LIMIT } from '../../lib/constants';
import useStore from '../../stores';

import { useHistory } from 'react-router-dom';

import { commands } from '@uiw/react-md-editor';
import MarkdownEditor from '../../components/MarkdownEditor/MarkdownEditor';

import BackArrow from '../../assets/iconography/back_arrow.svg';
import X from '../../assets/iconography/x.svg';

import { queryBuilder } from '../../lib/utils/queryBuilder';

export default function ConsultNote() {
  const history = useHistory();
  const { id } = useParams();
  const setRoute = useStore(state => state.route.setRoute);
  const token = useStore(state => state.admin.token);
  const setAdmin = useStore(state => state.admin.setAdmin);
  const [fetching, setFetching] = useState(true);
  const [publishOverlay, setPublishOverlay] = useState(null);
  const [params, setParams] = useState({});
  const [canPublish, setCanPublish] = useState(true);
  const [updated, setUpdated] = useState(false);
  const [errors, setErrors] = useState([]);
  const [macros, setMacros] = useState([]);

  const updateConsultNote = async () => {
    setErrors([]);
    setUpdated(false);
    const res = await fetch(`${API_BASE_PATH}/ops/admins/consults/${id}/note`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        edited_value: params.edited_value,
        status: params.status,
      }),
    });
    if (res.status === 200) {
      setUpdated(true);
      setPublishOverlay(false);
      setCanPublish(true);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
    } else {
      const { error } = await res.json();
      setErrors(prevArray => [...prevArray, error]);
    }
  };
  const updateConsultNoteStatus = async status => {
    setErrors([]);
    setUpdated(false);
    const res = await fetch(`${API_BASE_PATH}/ops/admins/consults/${id}/note`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        status,
      }),
    });
    if (res.status === 200) {
      setUpdated(true);
      setPublishOverlay(false);
      setParams(prevState => ({
        ...prevState,
        status,
      }));
      setCanPublish(true);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
    } else {
      const { error } = await res.json();
      setErrors(prevArray => [...prevArray, error]);
    }
  };
  useEffect(() => {
    async function getConsultNote() {
      setFetching(true);
      const res = await fetch(`${API_BASE_PATH}/ops/admins/consults/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      });
      if (res.status === 200) {
        const { consult } = await res.json();
        setParams(consult.consult_note);
        setFetching(false);
      } else if (res.status === 401) {
        await setAdmin(undefined, undefined);
      } else if (res.status === 500 || res.status === 404) {
        setErrors([
          'There was a problem loading your information, please try again later or contact support.',
        ]);
        setFetching(false);
      } else {
        const { error } = await res.json();
        setErrors(prevArray => [...prevArray, error]);
        setFetching(false);
      }
    }

    async function getMacros() {
      const res = await fetch(
        `${API_BASE_PATH}/ops/admins/macros?${queryBuilder({
          pageLimit: API_PAGE_LIMIT,
        })}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        },
      );
      if (res.status === 200) {
        const { macros } = await res.json();
        setMacros(macros);
      } else if (res.status === 401) {
        await setAdmin(undefined, undefined);
      }
    }
    setRoute('consults');
    getMacros();
    getConsultNote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeMacros = value => {
    let editedValue = value;

    const triggerPrefix = '.';
    const triggerKeys = [' ']; // triggers for macro expansion
    const lastChar = editedValue.slice(-1); // get the last character entered

    if (triggerKeys.includes(lastChar)) {
      const words = editedValue.trim().split(/\s+/); // split by any whitespace
      let lastWord = words[words.length - 1];

      if (lastWord.startsWith(triggerPrefix)) {
        const lastIndex = editedValue.lastIndexOf(lastWord);
        lastWord = lastWord.substring(triggerPrefix.length); //remove the triggerPrefix

        const macro = macros.find(macro => macro.macro_keyword === lastWord);

        if (macro) {
          editedValue = editedValue.substring(0, lastIndex) + macro.macro_text;
        }
      }
    }

    return editedValue;
  };

  return (
    <div className='flex flex-col flex-auto bg-gray-50 items-stretch max-w-full justify-center'>
      {publishOverlay ? (
        <div className='fixed inset-0 w-screen h-screen bg-black bg-opacity-50 z-50 items-center flex justify-center'>
          <div className='m-10 lg:w-full lg:max-w-xl relative bg-white rounded-md border border-solid border-gray-500 py-4'>
            <div className='border-b border-solid border-gray-300'>
              <div className='w-full flex justify-between items-center py-2 px-4'>
                <div className='p-2 items-center flex justify-center'>
                  <span className='font-serif text-lg'>
                    {params?.status === 'published'
                      ? 'Are you sure you want to unpublish?'
                      : 'Are you ready to publish?'}
                  </span>
                </div>
                <div
                  onClick={() => setPublishOverlay(false)}
                  className='ml-12 hover:bg-gray-300 hover:bg-opacity-50 rounded-md cursor-pointer p-2 items-center flex justify-center'
                >
                  <img
                    src={X}
                    className='relative block h-5 w-5'
                    alt='Pelago, smart packaging'
                  />
                </div>
              </div>
            </div>
            <div className='border-b border-solid border-gray-300'>
              <div className='w-full flex justify-between items-center py-6 px-4'>
                <span className='text-sm'>
                  {params?.status === 'published'
                    ? 'This will remove the note from the user.'
                    : `This will be visible to the user${
                        params.date_email_sent
                          ? ''
                          : ' and an email will go out notifying them immediately'
                      }.`}
                </span>
              </div>
            </div>
            <div className='flex justify-end px-4 pt-4'>
              <button
                onClick={() => setPublishOverlay(false)}
                className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-transparent hover:bg-gray-200 rounded-md border border-solid border-gray-300 text-gray-900 transition-all duration-200 ease-in-out delay-75'
              >
                Cancel
              </button>
              <button
                onClick={() =>
                  updateConsultNoteStatus(
                    params.status === 'published' ? 'draft' : 'published',
                  )
                }
                className='ml-4 flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-pink-700 hover:bg-pink-900 rounded-md border border-solid border-pink-900 text-white transition-all duration-200 ease-in-out delay-75'
              >
                Yes, {params.status === 'published' ? 'unpublish' : 'publish'}
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <div className='relative flex-auto mx-auto my-0 py-0 px-4 lg:px-12 max-w-6xl w-full'>
        <div className='pt-4 lg:pt-6 px-0 relative w-full'>
          <div className='flex justify-between'>
            <div className='flex-none max-w-full mr-2 lg:mr-6'>
              <NavLink
                to={`/consults/${id}`}
                className='flex relative items-center min-h-14 m-0 p-1 bg-transparent border border-solid border-gray-300 rounded-md'
              >
                <div className='flex relative items-center justify-center p-0 m-0 bg-transparent h-8 w-8'>
                  <div className='m-0'>
                    <img
                      src={BackArrow}
                      className='relative block h-5 w-5'
                      alt='Tiny Ops'
                    />
                  </div>
                </div>
              </NavLink>
            </div>
            <div className='mt-0 flex-auto self-center'>
              <h1 className='font-serif text-xl lg:text-2xl break-word leading-8 m-0'>
                Consult Note {id}
              </h1>
              <div className='flex text-center'>
                <span
                  className={`text-lg text-center rounded-2xl p-2 bg-${
                    params?.status === 'draft'
                      ? 'yellow'
                      : params?.status === 'published'
                      ? 'green'
                      : 'red'
                  }-100 mb-1 w-24`}
                >
                  {params?.status}
                </span>
              </div>
            </div>
            <div className='flex flex-auto content-end items-start justify-end ml-6 whitespace-nowrap'>
              <div className='ml-2 lg:ml-4 mt-0 flex'>
                <button
                  onClick={() => updateConsultNote()}
                  disabled={fetching}
                  className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-purple-500 hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75'
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          <div className='mb-3'>
            {updated ? (
              <div className='bg-green-50 outline-none shadow-md rounded-md border border-solid border-green-500 mt-4'>
                <div className='flex flex-col p-6'>
                  <div className='font-medium'>
                    Consults {id} successfully updated
                  </div>
                </div>
              </div>
            ) : null}
            {errors && errors.length > 0 ? (
              <div className='bg-pink-50 outline-none shadow-md rounded-md border border-solid border-pink-500 mt-4'>
                <div className='flex flex-col p-3'>
                  <div className='font-semibold'>
                    There{' '}
                    {errors.length === 1
                      ? 'is 1 error'
                      : `are ${errors.length} errors`}{' '}
                    with this expert review:
                  </div>
                  <div className='ml-4 pl-4'>
                    <ul className='list-disc'>
                      {errors.map((error, index) => {
                        return (
                          <li key={index} className='mt-2'>
                            {error}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
            <div className='flex flex-col justify-center items-start lg:flex-1'>
              <div className='flex flex-col mt-6 lg:flex-1 w-full'>
                <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300'>
                  <div className='p-8 rounded-md' data-color-mode='light'>
                    <div className='pb-4'>
                      <div className='flex justify-between'>
                        <div className='mt-0 flex-auto self-center'>
                          <h2 className='font-serif font-semibold m-0 text-lg'>
                            Edited note
                          </h2>
                          <span className='text-gray-500'>
                            User will see edited note if there is one.
                          </span>
                        </div>
                        <div className='flex flex-auto content-end items-start justify-end ml-6 whitespace-nowrap'>
                          <div className='ml-2 lg:ml-4 mt-0 flex'>
                            <button
                              onClick={() => setPublishOverlay(true)}
                              disabled={fetching || !canPublish}
                              className={`flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content ${
                                params?.status === 'published'
                                  ? 'bg-red-600'
                                  : 'bg-purple-500'
                              } enabled:hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75 disabled:opacity-25`}
                            >
                              {params?.status === 'published'
                                ? 'Unpublish'
                                : 'Publish'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <MarkdownEditor
                      height={300}
                      value={params?.edited_value}
                      onChange={value => {
                        const editedValue = handleChangeMacros(value);
                        setParams(prevState => ({
                          ...prevState,
                          edited_value: editedValue,
                        }));
                        setCanPublish(false);
                      }}
                      highlightEnable={false}
                      autoSyncScroll={true}
                    />
                  </div>
                </div>
                <div>
                  <Link
                    to={{
                      pathname:
                        'https://tinyhealth.atlassian.net/wiki/spaces/TH/pages/214073356/Consult+communication+templates',
                    }}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='underline text-blue-600 pl-2 text-sm'
                  >
                    See All Templates
                  </Link>
                </div>
              </div>
              <div className='flex flex-col mt-6 lg:flex-1 w-full'>
                <h2 className='font-serif font-semibold m-0 text-lg'>
                  Note history
                </h2>
                <div className='flex-1 max-w-full'>
                  <div className='flex flex-col relative'>
                    <label className='mb-1 p-0 text-sm'>
                      {params?.date_email_sent
                        ? `First published on ${params?.date_email_sent}`
                        : 'Not yet published'}
                    </label>
                  </div>
                </div>
              </div>
              <div className='flex flex-col mt-6 lg:flex-1 w-full'>
                <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300'>
                  <div className='p-8 rounded-md' data-color-mode='light'>
                    <div className='pb-4 flex gap-4 items-center justify-between'>
                      <h2 className='font-serif font-semibold m-0 text-lg'>
                        Previous note
                      </h2>
                    </div>
                    <MarkdownEditor
                      height={300}
                      value={params?.original_value}
                      preview={'edit'}
                      hideToolbar={true}
                      textareaProps={{ disabled: true }}
                      commands={[commands.codeEdit]}
                      highlightEnable={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
