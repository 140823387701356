// ALL CONSTANTS
export const jwtToken = 'jwtToken';

export const API_PAGE_LIMIT = 1000;

export const kitStatuses = [
  'activated',
  'bioinformatics_hold',
  'confirm_kit_returned_to_lab_by_customer',
  'delivered_to_customer',
  'delivered_to_lab',
  'expert_review_complete',
  'initialized',
  'in_transit_to_customer',
  'in_transit_to_lab',
  'label_expiry_email_sent',
  'lab_reminder_sent',
  'pipeline_complete',
  'sequences_delivered',
  'results_ready_practitioner',
  'results_ready',
  'return_missing',
  'void_customer_refund',
  'void_internal',
  'void_lab_error',
  'void_lost_in_mail',
  'void_return_label_expired',
  'void_sample_expired',
  'void_shippo_shipment_failed',
];

export const consultStatuses = [
  'cancelled',
  'completed_phone',
  'completed_recording',
  'completed_video',
  'no_show_completed',
  'no_show_incomplete',
  'scheduled',
];

export const consultTypes = [
  'coaching_programs',
  'feedback',
  'intro_practitioner',
  'research_study_participant',
  'results_review_affiliate',
  'results_review_customer',
  'results_review_practitioner',
];

export const fulfillmentStatuses = ['cancelled', 'done', 'pending'];

export const recommendationItemTypes = [
  'food',
  'probiotic',
  'product',
  'recipe',
  'resource',
  'supplement',
  'tip',
];

export const validLabPartners = [
  { id: 'PsomagenNG_MD', label: 'Psomagen NG (Maryland) - Default' },
  { id: 'PsomagenNG', label: 'Psomagen NG (Virginia)' },
  { id: 'Psomagen', label: 'Psomagen (OLD)' },
  { id: 'Cosmos', label: 'Cosmos' },
  { id: 'Zymo', label: 'Zymo' },
];
