import { useState, useEffect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import Calendar from 'react-calendar';
import moment from 'moment';
import { API_BASE_PATH } from '../../config/api';
import useStore from '../../stores';
import X from '../../assets/iconography/x.svg';
import BackArrow from '../../assets/iconography/back_arrow.svg';
import KitSection from '../../components/KitSection/KitSection';
import PractitionerDropdown from '../../components/PractitionerDropdown/PractitionerDropdown';

import Table from '../../components/Table';

import { queryBuilder } from '../../lib/utils/queryBuilder';

import {
  consultTableColumns,
  feedbackTableColumns,
  emailTableColumns,
  consultCreditTableColumns,
  npsTableColumns,
  subscriptionsTableColumns,
} from './helper';

export default function MainAccount() {
  const { id } = useParams();
  const history = useHistory();
  const setRoute = useStore(state => state.route.setRoute);
  const token = useStore(state => state.admin.token);
  const setAdmin = useStore(state => state.admin.setAdmin);
  const [addCreditOverlay, setAddCreditOverlay] = useState(false);
  const [deleteCreditOverlay, setDeleteCreditOverlay] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [fetchingPractitioners, setFetchingPractitioners] = useState(true);
  const [fetchingFeedback, setFetchingFeedback] = useState(true);
  const [fetchingNps, setFetchingNps] = useState(true);
  const [fetchingConsultCredits, setFetchingConsultCredits] = useState(true);
  const [params, setParams] = useState({});
  const [practitioners, setPractitioners] = useState([]);
  const [feedback, setFeedback] = useState([]);
  const [nps, setNps] = useState([]);

  const [consultCredits, setConsultCredits] = useState([]);
  const [allKits, setAllKits] = useState([]);
  const [updated, setUpdated] = useState(false);
  const [errors, setErrors] = useState([]);
  const [practitionersErrors, setPractitionersErrors] = useState([]);
  const [feedbackErrors, setFeedbackErrors] = useState([]);
  const [npsErrors, setNpsErrors] = useState([]);

  const [consultCreditErrors, setConsultCreditErrors] = useState([]);
  const [openCalendar, setOpenCalendar] = useState();
  const [nextSuggestedConsultDate, setNextSuggestedConsultDate] =
    useState(null);
  const [dateMembershipUpgraded, setDateMembershipUpgraded] = useState(null);
  const [dateMembershipDowngraded, setDateMembershipDowngraded] =
    useState(null);

  const addConsultCredit = async () => {
    setErrors([]);
    setUpdated(false);

    const res = await fetch(
      `${API_BASE_PATH}/ops/admins/mainaccounts/${id}/consult-credits`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ notes: params.newConsultCreditNotes }),
      },
    );
    if (res.status === 200) {
      window.scrollTo(0, 0);
      setAddCreditOverlay(false);
      getConsultCreditList();
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
    } else {
      const { error } = await res.json();
      setConsultCreditErrors(prevArray => [...prevArray, error]);
    }
  };

  const getConsultCreditList = async () => {
    setFetchingConsultCredits(true);
    const res = await fetch(
      `${API_BASE_PATH}/ops/admins/mainaccounts/${id}/consult-credits`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      },
    );
    if (res.status === 200) {
      const { consultCredits } = await res.json();
      setConsultCredits(consultCredits);
      setFetchingConsultCredits(false);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setConsultCreditErrors([
        'There was a problem loading the consult credits list, please try again later',
      ]);
      setFetchingConsultCredits(false);
    } else {
      const { error } = await res.json();
      setConsultCreditErrors(prevArray => [...prevArray, error]);
      setFetchingConsultCredits(false);
    }
  };

  const deleteConsultCredit = async () => {
    setErrors([]);
    setUpdated(false);

    const availableConsultCredits = consultCredits.filter(
      cc => cc.status === 'CREATED',
    );

    if (availableConsultCredits.length === 0) {
      setErrors(['No available credit to delete.']);
      return;
    }

    const res = await fetch(
      `${API_BASE_PATH}/ops/admins/consult-credits/${availableConsultCredits[0].id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ notes: params.deletedConsultCreditNotes }),
      },
    );
    if (res.status === 200) {
      window.scrollTo(0, 0);
      setDeleteCreditOverlay(false);
      getConsultCreditList();
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
    } else {
      const { error } = await res.json();
      setConsultCreditErrors(prevArray => [...prevArray, error]);
    }
  };

  const updateMainaccount = async () => {
    setErrors([]);
    setUpdated(false);

    //commenting out for now, bug with checkValidDates with dateFormatRegex using calendar component
    // try {
    //   checkValidDates(params, [
    //     'date_membership_upgraded',
    //     'date_membership_downgraded',
    //   ]);
    // } catch (err) {
    //   setErrors([err.message]);
    //   return;
    // }

    const res = await fetch(`${API_BASE_PATH}/ops/admins/mainaccounts/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    if (res.status === 200) {
      setUpdated(true);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
    } else {
      const { error } = await res.json();
      setErrors(prevArray => [...prevArray, error]);
    }
  };
  useEffect(() => {
    async function getMainaccount() {
      setFetching(true);
      const res = await fetch(
        `${API_BASE_PATH}/ops/admins/mainaccounts/${id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        },
      );
      if (res.status === 200) {
        const { mainaccount } = await res.json();
        //dates handling
        setNextSuggestedConsultDate(
          mainaccount.next_suggested_consult_date
            ? moment(mainaccount?.next_suggested_consult_date).toDate()
            : null,
        );
        setDateMembershipUpgraded(
          mainaccount.date_membership_upgraded
            ? moment(mainaccount?.date_membership_upgraded).toDate()
            : null,
        );
        setDateMembershipDowngraded(
          mainaccount.date_membership_downgraded
            ? moment(mainaccount?.date_membership_downgraded).toDate()
            : null,
        );
        setParams(mainaccount);
        setFetching(false);
      } else if (res.status === 401) {
        await setAdmin(undefined, undefined);
      } else if (res.status === 500 || res.status === 404) {
        setErrors([
          'There was a problem loading your information, please try again later or contact support.',
        ]);
        setFetching(false);
      } else {
        const { error } = await res.json();
        setErrors(prevArray => [...prevArray, error]);
        setFetching(false);
      }
    }
    setRoute('mainaccounts');
    getMainaccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function getPractitionersList() {
      setFetchingPractitioners(true);
      const res = await fetch(
        `${API_BASE_PATH}/ops/admins/practitioners?${queryBuilder({
          orderBy: 'user.first_name',
          order: 'asc',
        })}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        },
      );
      if (res.status === 200) {
        const { practitioners } = await res.json();

        setPractitioners(practitioners);
        console.log();
        setFetchingPractitioners(false);
      } else if (res.status === 401) {
        await setAdmin(undefined, undefined);
      } else if (res.status === 500 || res.status === 404) {
        setPractitionersErrors([
          'There was a problem loading the practitioners list, please try again later',
        ]);
        setFetchingPractitioners(false);
      } else {
        const { error } = await res.json();
        setPractitionersErrors(prevArray => [...prevArray, error]);
        setFetchingPractitioners(false);
      }
    }
    getPractitionersList();
  }, []);

  useEffect(() => {
    async function getMainAccountFeedback() {
      setFetchingFeedback(true);
      const res = await fetch(
        `${API_BASE_PATH}/ops/admins/mainaccounts/${id}/feedback`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        },
      );
      if (res.status === 200) {
        const { feedback } = await res.json();
        setFeedback(feedback);
        setFetchingFeedback(false);
      } else if (res.status === 401) {
        await setAdmin(undefined, undefined);
      } else if (res.status === 500 || res.status === 404) {
        setFeedbackErrors([
          'There was a problem loading the feedback list, please try again later',
        ]);
        setFetchingFeedback(false);
      } else {
        const { error } = await res.json();
        setFeedbackErrors(prevArray => [...prevArray, error]);
        setFetchingFeedback(false);
      }
    }
    getMainAccountFeedback();
  }, []);
  useEffect(() => {
    getConsultCreditList();
  }, []);

  useEffect(() => {
    async function getMainAccountNps() {
      setFetchingNps(true);
      const res = await fetch(
        `${API_BASE_PATH}/ops/admins/mainaccounts/${id}/nps`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        },
      );
      if (res.status === 200) {
        const { nps } = await res.json();
        setNps(nps);
        setFetchingNps(false);
      } else if (res.status === 401) {
        await setAdmin(undefined, undefined);
      } else if (res.status === 500 || res.status === 404) {
        setNpsErrors([
          'There was a problem loading the nps list, please try again later',
        ]);
        setFetchingNps(false);
      } else {
        const { error } = await res.json();
        setNpsErrors(prevArray => [...prevArray, error]);
        setFetchingNps(false);
      }
    }
    getMainAccountNps();
  }, []);

  useEffect(() => {
    let tempAllKits = [];
    if (params?.tinyaccounts?.length) {
      params.tinyaccounts.forEach(ta => {
        ta?.kits?.forEach(kit => {
          tempAllKits.push({
            ...kit,
            ta_first_name: ta.first_name,
            ta_last_name: ta.last_name,
          });
        });
      });
    }
    setAllKits(tempAllKits);
  }, [params]);

  const emails_sms = [...(params.emails || []), ...(params.sms || [])].sort(
    (a, b) =>
      a.created_at < b.created_at ? 1 : a.created_at > b.created_at ? -1 : 0,
  );

  const creditStatusOrder = ['CREATED', 'SCHEDULED', 'DELETED'];
  const filteredConsultCredits = consultCredits.sort(function (a, b) {
    return (
      creditStatusOrder.indexOf(a.status) - creditStatusOrder.indexOf(b.status)
    );
  });

  return (
    <div className='flex flex-col flex-auto bg-gray-50 items-stretch max-w-full justify-center'>
      {addCreditOverlay ? (
        <div className='fixed inset-0 w-screen h-screen bg-black bg-opacity-50 z-50 items-center flex justify-center'>
          <div className='m-10 lg:w-full lg:max-w-xl relative bg-white rounded-md border border-solid border-gray-500 py-4'>
            <div className='border-b border-solid border-gray-300'>
              <div className='w-full flex justify-between items-center py-2 px-4'>
                <div className='p-2 items-center flex justify-center'>
                  <span className='font-serif text-lg'>Add Credit?</span>
                </div>
                <div
                  onClick={() => setAddCreditOverlay(false)}
                  className='ml-12 hover:bg-gray-300 hover:bg-opacity-50 rounded-md cursor-pointer p-2 items-center flex justify-center'
                >
                  <img
                    src={X}
                    className='relative block h-5 w-5'
                    alt='Pelago, smart packaging'
                  />
                </div>
              </div>
            </div>
            <div className='border-b border-solid border-gray-300'>
              <div className='w-full flex justify-between items-center py-6 px-4'>
                <span className='text-sm'>
                  Reason for adding a consult credit to this user account?
                </span>
              </div>
            </div>
            <textarea
              placeholder='(e.g. Free consult for change in results - RIT)'
              value={params.newConsultCreditNotes}
              onChange={e =>
                setParams(prevState => ({
                  ...prevState,
                  newConsultCreditNotes: e.target.value,
                }))
              }
              className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
            />
            <div className='flex justify-end px-4 pt-4'>
              <button
                onClick={() => setAddCreditOverlay(false)}
                className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-transparent hover:bg-gray-200 rounded-md border border-solid border-gray-300 text-gray-900 transition-all duration-200 ease-in-out delay-75'
              >
                Cancel
              </button>
              <button
                onClick={() => addConsultCredit()}
                className='ml-4 flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-pink-700 hover:bg-pink-900 rounded-md border border-solid border-pink-900 text-white transition-all duration-200 ease-in-out delay-75'
              >
                Add Credit
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {deleteCreditOverlay ? (
        <div className='fixed inset-0 w-screen h-screen bg-black bg-opacity-50 z-50 items-center flex justify-center'>
          <div className='m-10 lg:w-full lg:max-w-xl relative bg-white rounded-md border border-solid border-gray-500 py-4'>
            <div className='border-b border-solid border-gray-300'>
              <div className='w-full flex justify-between items-center py-2 px-4'>
                <div className='p-2 items-center flex justify-center'>
                  <span className='font-serif text-lg'>Delete Credit?</span>
                </div>
                <div
                  onClick={() => setDeleteCreditOverlay(false)}
                  className='ml-12 hover:bg-gray-300 hover:bg-opacity-50 rounded-md cursor-pointer p-2 items-center flex justify-center'
                >
                  <img
                    src={X}
                    className='relative block h-5 w-5'
                    alt='Pelago, smart packaging'
                  />
                </div>
              </div>
            </div>
            <div className='border-b border-solid border-gray-300'>
              <div className='w-full flex justify-between items-center py-6 px-4'>
                <span className='text-sm'>
                  Reason for deleting a consult credit to this user account?
                </span>
              </div>
            </div>
            <textarea
              placeholder='(e.g. Free consult for change in results - RIT)'
              value={params.deletedConsultCreditNotes}
              onChange={e =>
                setParams(prevState => ({
                  ...prevState,
                  deletedConsultCreditNotes: e.target.value,
                }))
              }
              className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
            />
            <div className='flex justify-end px-4 pt-4'>
              <button
                onClick={() => setDeleteCreditOverlay(false)}
                className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-transparent hover:bg-gray-200 rounded-md border border-solid border-gray-300 text-gray-900 transition-all duration-200 ease-in-out delay-75'
              >
                Cancel
              </button>
              <button
                onClick={() => deleteConsultCredit()}
                className='ml-4 flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-pink-700 hover:bg-pink-900 rounded-md border border-solid border-pink-900 text-white transition-all duration-200 ease-in-out delay-75'
              >
                Delete Credit
              </button>
            </div>
          </div>
        </div>
      ) : null}

      <div className='flex flex-col flex-auto bg-gray-50 items-stretch max-w-full justify-center'>
        <div className='relative flex-auto mx-auto my-0 py-0 px-4 lg:px-12 max-w-6xl w-full'>
          <div className='pt-4 lg:pt-6 px-0 relative w-full'>
            <div className='sticky top-0 z-30 pb-2 w-full bg-gray-50'>
              <div className='flex justify-between'>
                <div className='flex-none max-w-full mr-2 lg:mr-6'>
                  <button
                    onClick={() => history.goBack()}
                    className='flex relative items-center min-h-14 m-0 p-1 bg-transparent border border-solid border-gray-300 rounded-md'
                  >
                    <div className='flex relative items-center justify-center p-0 m-0 bg-transparent h-8 w-8'>
                      <div className='m-0'>
                        <img
                          src={BackArrow}
                          className='relative block h-5 w-5'
                          alt='Tiny Ops'
                        />
                      </div>
                    </div>
                  </button>
                </div>
                <div className='mt-0 flex-auto self-center'>
                  <h1 className='font-serif text-xl lg:text-2xl break-word leading-8 m-0'>
                    Main Account {params.id}
                  </h1>
                </div>
                <div className='flex flex-auto content-end items-start justify-end ml-6 whitespace-nowrap'>
                  <NavLink
                    className='ml-2 lg:ml-4 mt-0 flex'
                    to='/mainaccounts'
                  >
                    <button className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-transparent hover:bg-gray-200 rounded-md border border-solid border-gray-300 text-gray-900 transition-all duration-200 ease-in-out delay-75'>
                      Discard
                    </button>
                  </NavLink>
                  <div className='ml-2 lg:ml-4 mt-0 flex'>
                    <button
                      onClick={() => updateMainaccount()}
                      disabled={fetching}
                      className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-purple-500 hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75'
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className='mb-3 px-1'>
              {updated ? (
                <div className='bg-green-50 outline-none shadow-md rounded-md border border-solid border-green-500 mt-4'>
                  <div className='flex flex-col p-6'>
                    <div className='font-medium'>
                      Main account {params.id} successfully updated
                    </div>
                  </div>
                </div>
              ) : null}
              {errors && errors.length > 0 ? (
                <div className='bg-pink-50 outline-none shadow-md rounded-md border border-solid border-pink-500 mt-4'>
                  <div className='flex flex-col p-3'>
                    <div className='font-semibold'>
                      There{' '}
                      {errors.length === 1
                        ? 'is 1 error'
                        : `are ${errors.length} errors`}{' '}
                      with this main account:
                    </div>
                    <div className='ml-4 pl-4'>
                      <ul className='list-disc'>
                        {errors.map((error, idx) => (
                          <li className='mt-2' key={idx}>
                            {error}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ) : null}
              <form autoComplete='off' className='mb-10 lg:flex'>
                <div className='flex flex-col lg:flex-row justify-center items-start lg:flex-1'>
                  <div className='flex flex-col mt-6 lg:flex-2 w-full'>
                    <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300'>
                      <div className='p-8 rounded-md'>
                        <div className='pb-4'>
                          <h2 className='font-serif font-semibold m-0 text-lg'>
                            General information
                          </h2>
                        </div>
                        <div className='flex-1 max-w-full'>
                          <div className='flex flex-col relative'>
                            <label className='mb-1 p-0 text-sm'>Id</label>
                            <div className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 bg-grey-200 rounded-md text-sm cursor-not-allowed'>
                              {id}
                            </div>
                          </div>
                        </div>

                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='mb-1 p-0 text-sm'>
                              First Name
                            </label>
                            <input
                              placeholder='Ada'
                              value={params.first_name}
                              onChange={e =>
                                setParams(prevState => ({
                                  ...prevState,
                                  first_name: e.target.value,
                                }))
                              }
                              className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                            />
                          </div>
                        </div>

                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='mb-1 p-0 text-sm'>
                              Last Name
                            </label>
                            <input
                              placeholder='Lovelace'
                              value={params.last_name}
                              onChange={e =>
                                setParams(prevState => ({
                                  ...prevState,
                                  last_name: e.target.value,
                                }))
                              }
                              className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                            />
                          </div>
                        </div>

                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='mb-1 p-0 text-sm'>Email</label>
                            <div className='flex'>
                              <div className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 bg-grey-200 rounded-md text-sm cursor-not-allowed'>
                                {params.email}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='mb-1 p-0 text-sm'>
                              Last Login
                            </label>
                            <div className='flex'>
                              <div className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 bg-grey-200 rounded-md text-sm cursor-not-allowed'>
                                {params.user?.last_login_at ?? 'N/A' }
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='mb-1 p-0 text-sm'>
                              Phone Number
                            </label>
                            <div className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 bg-grey-200 rounded-md text-sm cursor-not-allowed'>
                              {params.phone_number}
                            </div>
                          </div>
                        </div>

                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='mb-1 p-0 text-sm'>
                              Practitioner
                            </label>
                            {fetchingPractitioners ? (
                              'Loading practitioners list...'
                            ) : !practitionersErrors?.length ? (
                              <PractitionerDropdown
                                practitioner_id={params.practitioner_id}
                                onChange={e =>
                                  setParams(prevState => ({
                                    ...prevState,
                                    practitioner_id: e?.value,
                                  }))
                                }
                                practitioners={practitioners}
                              />
                            ) : (
                              <>
                                <p>{params.practitioner_id}</p>
                                <div className='bg-pink-50 outline-none shadow-md rounded-md border border-solid border-pink-500 mt-4'>
                                  <div className='flex flex-col p-3'>
                                    <div className='ml-4 pl-4'>
                                      <ul className='list-disc'>
                                        {practitionersErrors.map(
                                          (error, idx) => (
                                            <li className='mt-2' key={idx}>
                                              {error}
                                            </li>
                                          ),
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>

                        <div className='mt-4 flex-1 max-w-full'>
                          <div className='flex'>
                            <input
                              className='cursor-pointer w-4'
                              type='checkbox'
                              checked={params.priority_account}
                              onChange={e =>
                                setParams(prevState => ({
                                  ...prevState,
                                  priority_account: e.target.checked,
                                }))
                              }
                            />
                            <label className='ml-2 pt-1 text-sm'>
                              Is a Priority Account
                            </label>
                          </div>
                        </div>

                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='mb-1 p-0 text-sm'>Comments</label>
                            <textarea
                              placeholder='Internal comments'
                              value={params.comments}
                              onChange={e =>
                                setParams(prevState => ({
                                  ...prevState,
                                  comments: e.target.value,
                                }))
                              }
                              className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 mt-8'>
                      <div className='p-8 rounded-md'>
                        <div className='pb-4'>
                          <h2 className='font-serif font-semibold m-0 text-lg'>
                            Membership Info
                          </h2>
                        </div>
                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='mb-1 p-0 text-sm'>
                              Membership Type
                            </label>
                            <select
                              value={params.membership_type}
                              name='membership_type'
                              onChange={e =>
                                setParams(prevState => ({
                                  ...prevState,
                                  membership_type: e.target.value,
                                }))
                              }
                              className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                            >
                              <option value='free'>Free</option>
                              <option value='paid'>Paid</option>
                              <option value='gifted'>Gifted</option>
                            </select>
                          </div>
                        </div>
                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='mb-1 p-0 text-sm'>
                              Membership Plan
                            </label>
                            <select
                              value={params.membership_plan}
                              name='membership_plan'
                              onChange={e =>
                                setParams(prevState => ({
                                  ...prevState,
                                  membership_plan: e.target.value,
                                }))
                              }
                              className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                            >
                              <option value=''>None</option>
                              <option value='tiny_plus'>Tiny+ (Legacy)</option>
                              <option value='tiny_plus_proactive'>
                                Tiny+ Proactive
                              </option>
                              <option value='tiny_plus_programs'>
                                Tiny+ Programs
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='mb-1 p-0 text-sm'>
                              Date Membership Upgraded
                            </label>
                            <input
                              placeholder='March 4th 2007'
                              value={
                                !dateMembershipUpgraded
                                  ? null
                                  : moment(dateMembershipUpgraded).format(
                                      'YYYY-MM-DD',
                                    )
                              }
                              onClick={() => {
                                setOpenCalendar(
                                  openCalendar === 'date_membership_upgraded'
                                    ? null
                                    : 'date_membership_upgraded',
                                );
                              }}
                              className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                            />
                            <div
                              className={
                                openCalendar === 'date_membership_upgraded'
                                  ? 'absolute z-50 top-20 right-0'
                                  : 'hidden'
                              }
                            >
                              <Calendar
                                value={dateMembershipUpgraded}
                                onChange={value => {
                                  setDateMembershipUpgraded(value);
                                  setParams(prevState => ({
                                    ...prevState,
                                    date_membership_upgraded:
                                      moment(value).format('YYYY-MM-DD'),
                                  }));
                                  setOpenCalendar(false);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='mb-1 p-0 text-sm'>
                              Date Membership Downgraded
                            </label>
                            <input
                              placeholder='March 4th 2007'
                              value={
                                !dateMembershipDowngraded
                                  ? null
                                  : moment(dateMembershipDowngraded).format(
                                      'YYYY-MM-DD',
                                    )
                              }
                              onClick={() => {
                                setOpenCalendar(
                                  openCalendar === 'date_membership_downgraded'
                                    ? null
                                    : 'date_membership_downgraded',
                                );
                              }}
                              className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                            />
                            <div
                              className={
                                openCalendar === 'date_membership_downgraded'
                                  ? 'absolute z-50 top-20 right-0'
                                  : 'hidden'
                              }
                            >
                              <Calendar
                                value={dateMembershipDowngraded}
                                onChange={value => {
                                  setDateMembershipDowngraded(value);
                                  setParams(prevState => ({
                                    ...prevState,
                                    date_membership_downgraded:
                                      moment(value).format('YYYY-MM-DD'),
                                  }));
                                  setOpenCalendar(false);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 mt-8'>
                      <div className='p-8 rounded-md'>
                        <h2 className='font-serif font-semibold m-0 text-lg'>
                          Subscriptions
                        </h2>
                        {params?.subscriptions?.length > 0 ? (
                          <div className='flex-1 max-w-full'>
                            <div className='flex flex-col relative'>
                              <div className='w-full border-collapse'>
                                <Table
                                  type={'subscriptions'}
                                  columns={subscriptionsTableColumns}
                                  staticData={params.subscriptions}
                                ></Table>
                              </div>
                            </div>
                          </div>
                        ) : (
                          'No subscriptions'
                        )}
                      </div>
                    </div>
                    <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 mt-8'>
                      <div className='p-8 rounded-md'>
                        <div className='pb-4'>
                          <h2 className='font-serif font-semibold m-0 text-lg mb-1'>
                            Consults
                          </h2>
                          <div className='flex-1 max-w-full'>
                            <div className='flex flex-col relative'>
                              <label className='mb-1 p-0 text-sm'>
                                Next suggested consult date
                              </label>
                              <input
                                placeholder='March 4th 2024'
                                value={
                                  !nextSuggestedConsultDate
                                    ? null
                                    : moment(nextSuggestedConsultDate).format(
                                        'YYYY-MM-DD',
                                      )
                                }
                                onClick={() => {
                                  setOpenCalendar(
                                    openCalendar ===
                                      'next_suggested_consult_date'
                                      ? null
                                      : 'next_suggested_consult_date',
                                  );
                                }}
                                className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                              />
                              <div
                                className={
                                  openCalendar === 'next_suggested_consult_date'
                                    ? 'absolute z-50 top-20 right-0'
                                    : 'hidden'
                                }
                              >
                                <Calendar
                                  value={nextSuggestedConsultDate}
                                  onChange={value => {
                                    setNextSuggestedConsultDate(value);
                                    setParams(prevState => ({
                                      ...prevState,
                                      next_suggested_consult_date:
                                        moment(value).format('YYYY-MM-DD'),
                                    }));
                                    setOpenCalendar(false);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='flex-1 max-w-full'>
                            <div className='flex flex-col relative'>
                              <label className='mb-1 p-0 text-sm mt-1'>
                                Booked
                              </label>
                              {params?.consults?.length > 0 ? (
                                <div className='flex-1 max-w-full'>
                                  <div className='flex flex-col relative'>
                                    <div className='w-full border-collapse'>
                                      <Table
                                        type={'consults'}
                                        columns={consultTableColumns}
                                        staticData={params.consults}
                                      ></Table>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className='mb-3'>No Consults</div>
                              )}
                            </div>
                          </div>
                          <div className='flex-1 max-w-full'>
                            <div className='flex flex-col relative'>
                              <label className='mb-1 p-0 mt-1 text-sm'>
                                Credits
                              </label>
                              <div className='ml-2 lg:ml-4 mt-0 flex'>
                                <button
                                  type='button'
                                  onClick={() => setAddCreditOverlay(true)}
                                  disabled={fetching}
                                  className='flex h-10 justify-center items-center relative overflow-hidden px-2 pt-1 lg:px-5 text-sm min-w-content bg-transparent hover:bg-gray-200 rounded-md border border-solid border-gray-300 text-gray-900 transition-all duration-200 ease-in-out delay-75'
                                >
                                  Add Credit
                                </button>
                                {consultCredits.filter(
                                  cc => cc.status === 'CREATED',
                                ).length > 0 ? (
                                  <button
                                    type='button'
                                    onClick={() => setDeleteCreditOverlay(true)}
                                    disabled={fetching}
                                    className='flex h-10 justify-center items-center relative overflow-hidden px-2 pt-1 lg:px-5 text-sm min-w-content bg-transparent hover:bg-gray-200 rounded-md border border-solid border-gray-300 text-gray-900 transition-all duration-200 ease-in-out delay-75'
                                  >
                                    Delete Credit
                                  </button>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className='flex-1 max-w-full'>
                            <div className='flex flex-col relative'>
                              {fetchingConsultCredits ? (
                                'Loading Consult Credits'
                              ) : filteredConsultCredits?.length > 0 ? (
                                <div className='flex-1 max-w-full'>
                                  <div className='flex flex-col relative'>
                                    <div className='w-full border-collapse'>
                                      <Table
                                        type={'consultCredits'}
                                        columns={consultCreditTableColumns}
                                        staticData={filteredConsultCredits}
                                      ></Table>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                'No Consult Credits'
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 mt-8'>
                      <div className='p-8 rounded-md'>
                        <h2 className='font-serif font-semibold m-0 text-lg'>
                          Feedback
                        </h2>
                        {fetchingFeedback ? (
                          'Loading feedback list...'
                        ) : feedback?.length > 0 ? (
                          <div className='flex-1 max-w-full'>
                            <div className='flex flex-col relative'>
                              <div className='w-full border-collapse'>
                                <Table
                                  type={'feedback'}
                                  columns={feedbackTableColumns}
                                  staticData={feedback}
                                ></Table>
                              </div>
                            </div>
                          </div>
                        ) : (
                          'No Feedback'
                        )}
                      </div>
                    </div>
                    <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 mt-8'>
                      <div className='p-8 rounded-md'>
                        <h2 className='font-serif font-semibold m-0 text-lg'>
                          NPS Rating
                        </h2>
                        {fetchingNps ? (
                          'Loading NPS Ratings...'
                        ) : nps?.length > 0 ? (
                          <div className='flex-1 max-w-full'>
                            <div className='flex flex-col relative'>
                              <div className='w-full border-collapse'>
                                <Table
                                  type={'nps'}
                                  columns={npsTableColumns}
                                  staticData={nps}
                                ></Table>
                              </div>
                            </div>
                          </div>
                        ) : (
                          'No NPS Ratings'
                        )}
                      </div>
                    </div>
                    <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 mt-8'>
                      <div className='rounded-md'>
                        <h2 className='p-8 font-serif font-semibold m-0 text-lg'>
                          Automated Email/SMS history
                        </h2>
                        {emails_sms.length > 0 ? (
                          <div className='flex-1 max-w-full'>
                            <div className='flex flex-col relative'>
                              <div className='w-full border-collapse'>
                                <Table
                                  type={'emails'}
                                  columns={emailTableColumns}
                                  staticData={emails_sms}
                                  disabled={filter => true}
                                ></Table>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className='flex lg:mt-6 mt-4 lg:ml-8 lg:flex-1 flex-col w-full'>
                    <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 flex flex-col flex-1'>
                      <div className='px-4 lg:px-8 pt-8'>
                        <h2 className='font-serif font-semibold m-0 text-lg'>
                          Family members
                        </h2>
                      </div>
                      {params?.tinyaccounts?.length > 0 ? (
                        <div className='px-4 lg:px-8 pt-4 pb-8'>
                          {params.tinyaccounts.map(tinyaccount => {
                            return (
                              <div
                                key={tinyaccount.id}
                                className='first:mt-0 mt-4'
                                id={tinyaccount.id}
                              >
                                <div className='flex-1 max-w-full break-all'>
                                  <div className='flex flex-col relative'>
                                    <NavLink
                                      to={`/tinyaccounts/${tinyaccount.id}`}
                                      className='flex flex-col relative m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                                    >
                                      <span className='font-semibold'>
                                        {tinyaccount.first_name}{' '}
                                        {tinyaccount.last_name}
                                      </span>
                                    </NavLink>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className='px-4 lg:px-8 pt-4 pb-8'>
                          <div className='flex-1 max-w-full'>
                            <div className='flex flex-col relative'>
                              <span className='flex-auto relative w-full m-0 text-sm'>
                                This family has no members
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='flex lg:mt-6 mt-4 lg:flex-1 flex-col w-full'>
                      <KitSection kits={allKits} title='Activated Kits' />
                    </div>

                    <div className='flex lg:mt-6 mt-4 lg:flex-1 flex-col w-full'>
                      <KitSection
                        kits={params?.unused_kits}
                        title='Purchased Kits'
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
