import React from 'react';

export default function SelectInput ({
  title,
  subtitle,
  options,
  value,
  onChange,
  children,
}: {
  title: string;
  subtitle?: string;
  options: { value: string | number; title: string; disabled?: boolean }[];
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  children?: React.ReactNode;
}) {
  return (
    <div className='flex-1 max-w-full mt-4'>
      <div className='flex flex-col relative'>
        <label className={`${subtitle ? '' : 'mb-1'} p-0 text-sm`}>
          {title}
        </label>
        {subtitle && (
          <label className='mb-1 p-0 text-xs opacity-60'>{subtitle}</label>
        )}
        <select
          value={value}
          name='Type'
          onChange={onChange}
          className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
        >
          {options.map(option => (
            <option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
            >
              {option.title}
            </option>
          ))}
        </select>
        {children}
      </div>
    </div>
  );
}
