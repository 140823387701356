import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Hamburger from 'hamburger-react';

import Logo from '../../assets/logo/logo.svg';
import { ReactComponent as Dashboard } from '../../assets/iconography/dashboard.svg';
import { ReactComponent as MainAccount } from '../../assets/iconography/mainaccounts.svg';
import { ReactComponent as TinyAccount } from '../../assets/iconography/tinyaccounts.svg';
import { ReactComponent as Kit } from '../../assets/iconography/kits.svg';
import { ReactComponent as Fulfillment } from '../../assets/iconography/fulfillments.svg';
import { ReactComponent as Consult } from '../../assets/iconography/consults.svg';
import { ReactComponent as Subscription } from '../../assets/iconography/subscription.svg';
import { ReactComponent as Practitioner } from '../../assets/iconography/practitioners.svg';
import { ReactComponent as Logs } from '../../assets/iconography/log.svg';
import { ReactComponent as Settings } from '../../assets/iconography/settings.svg';
// import { ReactComponent as Overview } from '../../assets/iconography/overview.svg';
import { ReactComponent as Profile } from '../../assets/iconography/profile.svg';
import { ReactComponent as RegisterMainAccount } from '../../assets/iconography/register-main-account.svg';
import { ReactComponent as KeyInsight } from '../../assets/iconography/microbiome.svg';
import { ReactComponent as ActionPlan } from '../../assets/iconography/today.svg';
import { ReactComponent as Condition } from '../../assets/iconography/condition.svg';
import { ReactComponent as Guide } from '../../assets/iconography/book.svg';
import { ReactComponent as Swap } from '../../assets/iconography/swap.svg';
import { ReactComponent as Recommendation } from '../../assets/iconography/recommendation.svg';
import { ReactComponent as MediaUpload } from '../../assets/images/icon/filled/paperclip.svg';

import { ReactComponent as ChevronUp } from '../../assets/iconography/chevron_up.svg';
import { ReactComponent as ChevronDown } from '../../assets/iconography/chevron_down.svg';

import useStore from '../../stores';

import './styles.css';

export default function Navbar() {
  const location = useLocation();
  const route = useStore(state => state.route.route);
  const setRoute = useStore(state => state.route.setRoute);
  const [isOpen, setOpen] = useState(false);
  const [resultsPortalOpen, setResultsPortalOpen] = useState(false);
  const [actionPlanOpen, setActionPlanOpen] = useState(false);

  const routeClicked = _route => {
    setRoute(_route);
    setOpen(false);
  };

  const getLink = (_route, text, IconComponent, divClassName) => {
    return (
      <NavLink to={`/${_route}`} onClick={() => routeClicked(_route)}>
        <li
          className={
            route === _route
              ? 'relative flex flex-wrap px-3 mb-1 rounded-md hover:bg-pink-50 nav-li-selected'
              : 'relative flex flex-wrap px-3 mb-1 rounded-md hover:bg-pink-50'
          }
        >
          <div className={`flex flex-nowrap w-full ${divClassName}`}>
            <div className='flex items-center no-underline'>
              <IconComponent
                className={
                  route === _route
                    ? 'w-3.5 h-3.5 mr-3 align-middle inline-block nav-icon-filter-selected'
                    : 'w-3.5 h-3.5 mr-3 align-middle inline-block'
                }
              />
              <span
                className={
                  route === _route
                    ? 'flex-auto leading-8 text-sm text-pink-900 no-underline'
                    : 'flex-auto leading-8 text-sm no-underline'
                }
              >
                {text ? text : parseCamelCase(_route)}
              </span>
            </div>
          </div>
        </li>
      </NavLink>
    );
  };

  const getMenuLink = (_route, text) => {
    return (
      <div className='block mx-0 py-3 border-b border-solid border-gray-900'>
        <NavLink
          to={`/${_route}`}
          onClick={() => routeClicked(_route)}
          className='flex py-2 justify-between items-center cursor-pointer no-underline leading-5 text-center'
        >
          {text ? text : parseCamelCase(_route)}
        </NavLink>
      </div>
    );
  };

  const parseCamelCase = value => {
    // Insert a space before all capital letters
    let result = value.replace(/([A-Z])/g, ' $1');

    // Convert the first character of each word to uppercase and the rest to lowercase
    result = result.toLowerCase().replace(/\b[a-z]/g, letter => {
      return letter.toUpperCase();
    });

    return result;
  };

  useEffect(() => {
    const initial_route = (location.pathname || '').replace('/', '');
    setRoute(initial_route);

    const baseRoute = initial_route.split('/')[0];

    if (
      [
        'actionPlanRecommendations',
        'recommendations',
        'recommendationItems',
      ].includes(baseRoute)
    ) {
      setResultsPortalOpen(true);
      setActionPlanOpen(true);
    } else if (
      [
        'conditions',
        'guides',
        'keyInsights',
        'keyInsightsSections',
        'keyInsightsCategory',
        'mediaUploads',
        'noteSummary',
        'macros',
        'profileQuestions',
      ].includes(baseRoute)
    ) {
      setResultsPortalOpen(true);
    }
  }, []);

  return (
    <div className='h-16 w-full lg:h-full lg:w-60 lg:min-h-screen fixed z-50 bg-gray-50 lg:border-r lg:border-grey-50'>
      <div className='flex flex-row lg:flex-col items-center lg:items-start justify-between h-full lg:h-screen py-4 px-4 lg:px-2'>
        <div className='flex items-center'>
          <img src={Logo} className='h-12' alt='Tiny Ops' />
        </div>
        <div className='flex lg:hidden relative'>
          <div className='z-50'>
            <Hamburger
              toggled={isOpen}
              toggle={() => setOpen(isOpen ? false : true)}
            />
          </div>
          {isOpen ? (
            <div className='fixed left-0 top-0 right-0 bottom-0 pt-20 px-7 block h-screen overflow-y-auto bg-pink-50'>
              {getMenuLink('mainaccounts', 'Main Accounts')}
              {getMenuLink('tinyaccounts', 'Tiny Accounts')}
              {getMenuLink('kits')}
              {getMenuLink('fulfillments')}
              {getMenuLink('consults')}
              {getMenuLink('subscriptions')}
              {getMenuLink('practitioners')}
              {getMenuLink('partner_register', 'Register Partner Kit')}
              {getMenuLink('actionPlanRecommendations', 'Action Plans')}
              {getMenuLink('recommendations', 'Rec. Lists')}
              {getMenuLink('recommendationItems', 'Rec. Items')}
              {getMenuLink('conditions')}
              {getMenuLink('guides')}
              {getMenuLink('keyInsights')}
              {getMenuLink('keyInsightsSections')}
              {getMenuLink('keyInsightsCategory', 'Key Insights Categories')}
              {getMenuLink('mediaUploads')}
              {getMenuLink('noteSummary')}
              {getMenuLink('macros')}
              {getMenuLink('shopifyProducts')}
              {getMenuLink('logs', 'Update Logs')}
              {getMenuLink('logout')}
            </div>
          ) : null}
        </div>
        <div className='hidden lg:flex flex-col justify-between h-screen overflow-y-auto'>
          <div className='flex flex-col max-w-full pt-4 w-full'>
            <ul className='m-0 p-0 list-none flex-auto'>
              {getLink('dashboard', null, Dashboard)}
              {getLink('mainaccounts', 'Main Accounts', MainAccount)}
              {getLink('tinyaccounts', 'Tiny Accounts', TinyAccount)}
              {getLink('kits', null, Kit)}
              {getLink('fulfillments', null, Fulfillment)}
              {getLink('consults', null, Consult)}
              {getLink('subscriptions', null, Subscription)}
              {getLink('practitioners', null, Practitioner)}
              {getLink(
                'partner_register',
                'Register Partner Kit',
                RegisterMainAccount,
              )}
              {getLink('profileQuestions', 'Profile Questions', TinyAccount)}
              {getLink('practitioner_swap', 'Swap practitioner email', Swap)}

              <div>
                <li
                  className={
                    'relative flex flex-wrap px-3 mb-1 rounded-md hover:bg-pink-50'
                  }
                >
                  <div className='flex flex-nowrap w-full'>
                    <div
                      className='flex items-center no-underline cursor-pointer'
                      onClick={() => setResultsPortalOpen(!resultsPortalOpen)}
                    >
                      {resultsPortalOpen ? (
                        <ChevronUp
                          className={
                            'w-3.5 h-3.5 mr-3 align-middle inline-block'
                          }
                        />
                      ) : (
                        <ChevronDown
                          className={
                            'w-3.5 h-3.5 mr-3 align-middle inline-block'
                          }
                        />
                      )}
                      <span
                        className={'flex-auto leading-8 text-sm no-underline'}
                      >
                        Results Portal
                      </span>
                    </div>
                  </div>
                  {resultsPortalOpen ? (
                    <div className='flex flex-nowrap w-full pl-6'>
                      <div
                        className='flex items-center no-underline cursor-pointer'
                        onClick={() => setActionPlanOpen(!actionPlanOpen)}
                      >
                        {actionPlanOpen ? (
                          <ChevronUp
                            className={
                              'w-3.5 h-3.5 mr-3 align-middle inline-block'
                            }
                          />
                        ) : (
                          <ChevronDown
                            className={
                              'w-3.5 h-3.5 mr-3 align-middle inline-block'
                            }
                          />
                        )}
                        <span
                          className={'flex-auto leading-8 text-sm no-underline'}
                        >
                          Action Plan
                        </span>
                      </div>
                    </div>
                  ) : null}
                </li>
              </div>
              {resultsPortalOpen && (
                <>
                  {actionPlanOpen && (
                    <>
                      {getLink(
                        'actionPlanRecommendations',
                        'Action Items',
                        ActionPlan,
                        'pl-12',
                      )}
                      {getLink(
                        'recommendations',
                        'Rec. Lists',
                        Recommendation,
                        'pl-12',
                      )}
                      {getLink(
                        'recommendationItems',
                        'Rec. Items',
                        Recommendation,
                        'pl-12',
                      )}
                    </>
                  )}

                  {getLink('conditions', null, Condition, 'pl-6')}
                  {getLink('guides', null, Guide, 'pl-6')}
                  {getLink('keyInsights', null, KeyInsight, 'pl-6')}
                  {getLink('keyInsightsSections', null, KeyInsight, 'pl-6')}
                  {getLink(
                    'keyInsightsCategory',
                    'Key Insights Categories',
                    KeyInsight,
                    'pl-6',
                  )}
                  {getLink(
                    'reportTaxaCategories',
                    'Taxa Categories',
                    ActionPlan,
                    'pl-6',
                  )}
                  {getLink('mediaUploads', null, MediaUpload, 'pl-6')}
                  {getLink('noteSummary', null, Guide, 'pl-6')}
                  {getLink('macros', null, Guide, 'pl-6')}
                </>
              )}
              {getLink('shopifyProducts', null, Subscription)}
              {getLink('logs', 'Update Logs', Logs)}
              {getLink('settings', null, Settings)}
            </ul>
            {/* <span className='text-sm px-1 mt-4 mb-2'>Analytics</span>
            <ul className='m-0 p-0 list-none flex-auto'>
              <NavLink to='/overview' onClick={() => setRoute('overview')}>
                <li
                  className={
                    route === 'overview'
                      ? 'relative flex flex-wrap px-3 mb-1 rounded-md hover:bg-pink-50 nav-li-selected'
                      : 'relative flex flex-wrap px-3 mb-1 rounded-md hover:bg-pink-50'
                  }
                >
                  <div className='flex flex-nowrap w-full'>
                    <div className='flex items-center no-underline'>
                      <Overview
                        className={
                          route === 'overview'
                            ? 'w-3.5 h-3.5 mr-3 align-middle inline-block nav-icon-filter-selected'
                            : 'w-3.5 h-3.5 mr-3 align-middle inline-block'
                        }
                      />
                      <span
                        className={
                          route === 'overview'
                            ? 'flex-auto leading-8 text-sm text-pink-900 no-underline'
                            : 'flex-auto leading-8 text-sm no-underline'
                        }
                      >
                        Overview
                      </span>
                    </div>
                  </div>
                </li>
              </NavLink>
            </ul> */}
          </div>
          <div className='flex'>
            <ul className='m-0 p-0 list-none flex-auto'>
              <NavLink to='/profile' onClick={() => setRoute('profile')}>
                <li
                  className={
                    route === 'profile'
                      ? 'relative flex flex-wrap px-3 mb-1 rounded-md hover:bg-pink-50 nav-li-selected'
                      : 'relative flex flex-wrap px-3 mb-1 rounded-md hover:bg-pink-50'
                  }
                >
                  <div className='flex flex-nowrap w-full'>
                    <div className='flex items-center no-underline'>
                      <Profile
                        className={
                          route === 'profile'
                            ? 'w-3.5 h-3.5 mr-3 align-middle inline-block nav-icon-filter-selected'
                            : 'w-3.5 h-3.5 mr-3 align-middle inline-block'
                        }
                      />
                      <span
                        className={
                          route === 'profile'
                            ? 'flex-auto leading-8 text-sm text-pink-900 no-underline'
                            : 'flex-auto leading-8 text-sm no-underline'
                        }
                      >
                        Profile
                      </span>
                    </div>
                  </div>
                </li>
              </NavLink>
              <NavLink to='/logout' onClick={() => setRoute('logout')}>
                <li
                  className={
                    route === 'logout'
                      ? 'relative flex flex-wrap px-3 mb-1 rounded-md hover:bg-pink-50 nav-li-selected'
                      : 'relative flex flex-wrap px-3 mb-1 rounded-md hover:bg-pink-50'
                  }
                >
                  <div className='flex flex-nowrap w-full'>
                    <div className='flex items-center no-underline'>
                      <span
                        className={
                          route === 'logout'
                            ? 'flex-auto leading-8 text-sm text-pink-900 no-underline'
                            : 'flex-auto leading-8 text-sm no-underline'
                        }
                      >
                        Logout
                      </span>
                    </div>
                  </div>
                </li>
              </NavLink>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
